define(['app'], (app) => () => {
  const comp = {};

  const _channel = 'loyaltyBirthdayReward/update';

  const _select = {
    dialogTrigger: '.loyaltyBirthdayReward_ctaContainer',
    dobElement: '.loyaltyBirthdayReward_activeMsg',
    dobText: '.loyaltyBirthdayReward_savedDOBText'
  };

  comp.init = element => {
    comp.element = element;
    comp.dialogTrigger = element.querySelector(_select.dialogTrigger);
    comp.dobElement = element.querySelector(_select.dobElement);
    comp.dobText = element.querySelector(_select.dobText);

    app.subscribe(_channel, comp.update);

    return comp;
  };

  comp.update = (dob) => {
    if (comp.dialogTrigger) {
      comp.dialogTrigger.setAttribute('data-show', 'false');
    }

    comp.dobElement.setAttribute('data-show', 'true');
    comp.dobText.innerText = dob;
  };

  return comp;
});
